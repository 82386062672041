import React from 'react';

import PropTypes from 'prop-types';

// withOverlayControls is a HOC used to wrap a component
// it adds the prop function `showOverlay` to the component
// which is used in tandem with `addOverlayControls` to show modals / dialogs
const withOverlayControls = (Component) =>
    class WithOverlayControlsWrapper extends React.PureComponent {
        static contextTypes = {
            showOverlay: PropTypes.func,
            hideOverlay: PropTypes.func,
            addOverlayNotification: PropTypes.func,
            hideOverlayNotification: PropTypes.func,
        };

        // Read showOverlay out of context and add it as a prop to the component
        render() {
            const {
                showOverlay,
                hideOverlay,
                addOverlayNotification,
                hideOverlayNotification,
            } = this.context;

            return (
                <Component
                    showOverlay={showOverlay}
                    hideOverlay={hideOverlay}
                    addOverlayNotification={addOverlayNotification}
                    hideOverlayNotification={hideOverlayNotification}
                    {...this.props}
                />
            );
        }
    };

export default withOverlayControls;
